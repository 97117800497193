body {
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background-color: #1a73e8;
  color: white;
  padding: 20px 0;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

h1 {
  margin: 0;
  font-size: 2.5em;
}

main {
  margin-top: 40px;
}

.cotizacion-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.cotizacion-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.cotizacion {
  background: white;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  flex: 0 1 300px;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;
}

.cotizacion:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0,0,0,0.15);
}

.cotizacion h2 {
  color: #1a73e8;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  border-bottom: 2px solid #1a73e8;
  padding-bottom: 10px;
}

.cotizacion-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cotizacion-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.label {
  font-weight: bold;
  color: #555;
}

.value {
  font-size: 1.2em;
  font-weight: bold;
}

.positive {
  color: #4caf50;
}

.negative {
  color: #f44336;
}

.variacion {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
}

.variacion.positive {
  background-color: rgba(76, 175, 80, 0.1);
}

.variacion.negative {
  background-color: rgba(244, 67, 54, 0.1);
}

footer {
  margin-top: 40px;
  text-align: center;
  color: #666;
}

#ultima-actualizacion {
  font-style: italic;
}

.auth-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  max-width: 400px;
  margin: 20px auto;
}

.auth-form h2 {
  text-align: center;
  color: #1a73e8;
}

.auth-form form {
  display: flex;
  flex-direction: column;
}

.auth-form input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.auth-form button {
  background-color: #1a73e8;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.auth-form button:hover {
  background-color: #1557b0;
}

.error {
  color: #f44336;
  text-align: center;
}

.operation-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  margin-top: 20px;
}

.operation-form h3 {
  margin-top: 0;
  color: #1a73e8;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.declaracion-jurada,
.operation-result {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  margin-top: 20px;
}

.operation-result pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .cotizacion-container {
    flex-direction: column;
  }
}
/* Estilos generales */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex: 1;
  padding: 20px;
}

/* Estilos del Navbar */
.navbar {
  background-color: #1a73e8;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand a {
  color: white;
  text-decoration: none;
  font-size: 1.5em;
  font-weight: bold;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-item {
  color: white;
  text-decoration: none;
  margin-left: 20px;
}

.logout-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1em;
}

/* Estilos para los formularios */
form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 20px auto;
}

input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  background-color: #1a73e8;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background-color: #1557b0;
}

/* Estilos para el Dashboard */
.dashboard {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cotizacion {
  margin-bottom: 20px;
}

.cotizacion h2 {
  color: #1a73e8;
}

.cotizacion-details {
  display: flex;
  justify-content: space-between;
}

.cotizacion-item {
  flex: 1;
  text-align: center;
}

.label {
  font-weight: bold;
}

.value {
  font-size: 1.2em;
}

.positive {
  color: green;
}

.negative {
  color: red;
}
/* Drawer styles */
.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
}

.drawer {
  background-color: white;
  width: 300px;
  height: 100%;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.drawer h2 {
  margin-top: 0;
}

.drawer form {
  display: flex;
  flex-direction: column;
}

.drawer .form-group {
  margin-bottom: 15px;
}

.drawer label {
  display: block;
  margin-bottom: 5px;
}

.drawer input,
.drawer select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.drawer button {
  background-color: #1a73e8;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.drawer button:hover {
  background-color: #1557b0;
}

.close-drawer {
  margin-top: 20px;
  background-color: #f44336 !important;
}

.close-drawer:hover {
  background-color: #d32f2f !important;
}
/* Estilos para el historial de operaciones */
.operation-history {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.operation-history h2 {
  color: #1a73e8;
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
}

.operation-history table {
  width: 100%;
  border-collapse: collapse;
}

.operation-history th,
.operation-history td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
}

.operation-history th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
}

.operation-history tr:hover {
  background-color: #f9f9f9;
}

.status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: bold;
}

.status.completed {
  background-color: #e6f4ea;
  color: #1e8e3e;
}

.status.failed {
  background-color: #fce8e6;
  color: #d93025;
}

.status.pending,
.status.awaiting_signature {
  background-color: #fff0b3;
  color: #f9ab00;
}

.view-details-btn {
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-details-btn:hover {
  background-color: #1557b0;
}

.operation-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.operation-details-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
}

.operation-details-content h3 {
  color: #1a73e8;
  margin-top: 0;
}

.close-details-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.close-details-btn:hover {
  background-color: #d32f2f;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #666;
}

.error {
  color: #d32f2f;
}
/* Add these styles for the filter inputs */
.operation-history table input {
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.operation-history table th {
  padding-top: 12px;
  padding-bottom: 12px;
}
/* Estilos para react-datepicker */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0;
}

.react-datepicker__close-icon::after {
  background-color: #1a73e8;
}
/* Estilos para la paginación */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.dashboard-content {
  text-align: center;
}

.fund-operations-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 18px;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.fund-operations-button:hover {
  background-color: #0056b3;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 18px;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin: 10px;
  cursor: pointer;
}

.dashboard-button:hover {
  background-color: #0056b3;
}


