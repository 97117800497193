.navbar {
  background-color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar-brand {
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar-brand a {
  font-size: 0;
}

.navbar-logo {
  max-width: 100px;
  max-height: 40px;
  width: auto;
  height: auto;
  object-fit: contain;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-item {
  margin-left: 15px;
  text-decoration: none;
  color: #333;
}

.navbar-item:hover {
  color: #007bff;
}

.logout-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #dc3545;
}

.logout-button:hover {
  text-decoration: underline;
}

.mep-order-button {
  color: white;
  background-color: #007bff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.mep-order-button:hover {
  background-color: #0056b3;
}
